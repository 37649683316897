<template>
    <div>
      <div>
        <v-dialog v-model="showForm" max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="permissions.PERMITBOOK_ADD"
              class="primary"
              @click="bookToEdit = undefined"
              small
              v-bind="attrs"
              v-on="on"
              >Add new permit book</v-btn
            >
          </template>
          <div>
            <PermitBookForm
              v-if="showForm"
              :permitBookToEdit="bookToEdit"
              @permitBookAdded="handlePermitBookAdded"
            />
          </div>
        </v-dialog>
        <div class="d-flex justify-space-start flex-wrap mt-3 flexWithGap">
          <v-text-field
            hide-details
            solo
            dense
            class="customTextField"
            label="Search driver by name"
            prepend-inner-icon="mdi-magnify"
            @input="handleFiltersApplied"
            v-model="filters.driver"
          ></v-text-field>
          <v-text-field
            hide-details
            solo
            dense
            class="customTextField"
            label="Search truck by number"
            prepend-inner-icon="mdi-magnify"
            @input="handleFiltersApplied"
            v-model="filters.truck"
          ></v-text-field>
          <v-text-field
            hide-details
            solo
            dense
            class="customTextField"
            label="Search trailer by number"
            prepend-inner-icon="mdi-magnify"
            @input="handleFiltersApplied"
            v-model="filters.trailer"
          ></v-text-field>
          <!-- <v-menu
            v-model="datePickerMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                solo
                dense
                class="customTextField"
                label="Search by contract due date"
                prepend-inner-icon="mdi-magnify"
                v-model="filters.date_expired"
                v-bind="attrs"
                v-on="on"
                readonly
                clearable
                @click:clear="filters.date_expired = null; handleFiltersApplied()"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="filters.date_expired"
              @input="datePickerMenu = false; handleFiltersApplied()"
              no-title
            ></v-date-picker>
          </v-menu> -->
          <v-select
            hide-details
            solo
            dense
            clearable
            @input="handleFiltersApplied"
            v-model="filters.company"
            item-text="name"
            item-value="short_name"
            :items="companiesFullSelect"
            label="Company"
            name="company"
            id="company"
          ></v-select>
          <v-checkbox
            v-model="filters.driversWithLessThan15Docs"
            label="Drivers with less than 15 docs"
            hide-details
            dense
            @change="handleFiltersApplied"
          ></v-checkbox>
          <!-- <v-text-field
            hide-details
            solo
            class="customTextField"
            label="Search company by name "
            prepend-inner-icon="mdi-magnify"
            @input="handleFiltersApplied"
            v-model="filters.company"
          ></v-text-field> -->
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="formatedUsers"
      :options="options"
      @update:options="handleOptionsChange"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :loading="tableLoading"
      disable-sort
      :no-data-text="'No users for applied filters'"
      class="elevation-1 mt-3 mb-10"
    >
      <template v-slot:item.document_status="{ item }">
        <td
          v-if="item.document_status"
          :class="item.document_status.color"
          class="text-center font-weight-bold customTd"
        >
          {{ item.document_status.message }}
        </td>
        <td v-else>/</td>
      </template>
      <template v-slot:item.preview="{ item }">
        <v-icon
          class="ml-5"
          v-bind="attrs"
          v-on="on"
          @click="getFilesForUser(item)"
        >
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      @input="handleDialogInput"
      v-model="showPermitBooks"
      max-width="90vw"
      max-height="90vh"
    >
      <PermitBookTable
        v-if="showPermitBooksTable"
        @permitBookDeleted="handlePermitBookAdded"
        @permitBookAdded="handlePermitBookAdded"
        :userId="userToDisplayPermitBooks"
        :date_expired_filter="filters.date_expired"
      ></PermitBookTable>
    </v-dialog>
  </div>
</template>
<script>
import PermitBookForm from './PermitBookForm.vue'
import PermitBookTable from './PermitBookTable.vue'
import PermitBookService from '../../services/PermitBookService'
import debounce from 'lodash/debounce' 
import {mapGetters} from 'vuex'
export default {
  name: 'PermitBookDrivers',
  components: {
    PermitBookForm,
    PermitBookTable,
  },
  data() {
    return {
      users: [],
      showPermitBooks: false,
      usersIdToDisplayPermitBooks: '',
      showPermitBooksTable: false,
      permitBooks: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Driver', value: 'driver' },
        { text: 'Status', value: 'document_status' },
        { text: 'Preview files', value: 'preview' },
      ],
      footerProps: {
        'items-per-page-options': [20, 50, 100],
      },
      options: {
        page: 1,
        itemsPerPage: 20,
      },
      filters: {
        driver: '',
        truck: '',
        trailer: '',
        company: '',
        // date_expired: '',
        driversWithLessThan15Docs: ''
      },
      datePickerMenu: false,
      showForm: false,
      totalItems: 0,
      tableLoading: true,
      attrs: {},
      on: {},
    }
  },
  computed: {
    ...mapGetters('common', ['loading', 'companiesFullSelect']),
    ...mapGetters('auth', ['permissions']),
    formatedUsers() {
      return this.users.map(user => {
        return {
          id: user._id,
          name: user.name,
          email: user.email,
          driver: user.driver ? user.driver.name : '/',
          document_status: user.document_status,
        }
      })
    }
  },
  async mounted() {
    await this.$store.dispatch('common/getAllCompanies')
    // await this.getDriverAppUsers()
  },
  methods: {
    async getDriverAppUsers() {
      const requestData = { ...this.options, ...this.filters }
      this.tableLoading = true
      try {
        const response = await PermitBookService.getPermitBookDrivers(requestData)
        this.users = response.data.users
        this.totalItems = response.data.totalUsers
      } catch (error) {
        this.users= []
      } finally {
        this.tableLoading = false
      }
    },
    async getFilesForUser(user) {
      this.showPermitBooksTable = true
      this.userToDisplayPermitBooks = user.id
      this.showPermitBooks = true
      user.showPermitBooks = true
    },
    async handlePermitBookAdded() {
      await this.getDriverAppUsers()
      this.showForm = false
    },
    handleDialogInput(value) {
      if (!value) {
        this.showPermitBooksTable = false
      }
    },
    handleFiltersApplied: debounce(async function () {
      this.options.page = 1
      await this.getDriverAppUsers()
    }, 500),
    handleOptionsChange(o) {
      this.options.page = o.page
      this.options.itemsPerPage = o.itemsPerPage
      this.getDriverAppUsers()
    },
  },
}
</script>
<style scoped>
.actionsBar {
  height: 48px;
}
.customTextField {
  width: 350px;
}
.customTd {
  width: 260px;
}
.flexWithGap{
  gap: 10px;
}
</style>
